import { useState } from "react";
import { Console_log } from "functions/common";

import * as env from "functions/env";
import LoginFrame from "templates/LoginFrame";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { forgetPassword } from "functions/api/mst";

export default function Main() {
  const [useMessage, setMessage] = useState("Send E-mail");
  const [useAxios] = useState("");

  const [useEmail, setEmail] = useState(env.API_LOGIN_USER());
  const emailChange = (e: any) => setEmail(e.target.value);
  const [usePass, setPass] = useState(env.API_LOGIN_PASS());
  const passChange = (e: any) => setPass(e.target.value);

  localStorage.clear();
  const getToken = () => {
    setMessage("stay, authentication");

    forgetPassword(useEmail)
      .then((res: any) => {
        setMessage(res.data.message);
      })
      .catch((error: any) => {
        setMessage("misstake");
        Console_log("misstake", error);
      });
  };

  return (
    <LoginFrame title={"Forget Password"}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        defaultValue={useEmail}
        onChange={emailChange}
      />
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={getToken}
      >
        {useMessage}
      </Button>
      <Grid container>
        <Grid item xs>
          {useAxios}
        </Grid>
      </Grid>
    </LoginFrame>
  );
}
