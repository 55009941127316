import { create } from "functions/axios";

// エンドユーザインプット関係
export function getEndInputs(params: any = {}) {
  const ins = create();
  return ins.get(`/end-input/index`, { params: params });
}
export function getEndInput(uuid: any = {}) {
  const ins = create();
  return ins.get(`/end-input/${uuid}`, {});
}

export function destroyEndInput(uuid: string) {
  const ins = create();
  return ins.delete(`/end-input/${uuid}/destroy`);
}

/**
 * 対象メール取込処理
 * @param uuid 
 * @returns 
 */
export function importEmail(uuid: string) {
  const ins = create();
  return ins.post(`/end-input/${uuid}/import`, {});
}
