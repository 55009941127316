import Template from "./Template";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { Console_log } from "functions/common";
import { Button, TextField, Grid, Typography } from "@mui/material";
import { getUser, updateUser } from "functions/api/mst";
import { CommonDataContext } from "Context";
import { tUser } from "types/User";

type urlProps = {
  id: string;
};
export default function Create() {
  const { id } = useParams<urlProps>();
  const [flgEdit, setFlgEdit] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { loading, loginUser } = useContext(CommonDataContext);
  const navigate = useNavigate();

  // ログインユーザと違うIDかを判定
  useEffect(() => {
    if (loginUser === null) {
      setFlgEdit(false);
      return;
    }
    if (id === undefined) {
      setFlgEdit(false);
      return;
    }

    if (loginUser.id != id) {
      setFlgEdit(false);
      return;
    }

    setFlgEdit(true);
  }, [loginUser, id]);

  useEffect(() => {
    if (id === undefined) {
      return;
    }

    getUser(id).then((res: any) => {
      Console_log("get:user.edit", res.data);

      const user: tUser = res.data;

      setName(user.name);
      setEmail(user.email);
    });
  }, [id]);

  //更新ボタンを押下したときの処理
  const handleUpdate = () => {
    if (loginUser === null) return;
    if (!id) return;

    updateUser(id, {
      domain: loginUser.domain,
      name: name,
      email: email,
    })
      .then((res: any) => {
        //setWeekData(res.data);
        alert(res.data.message);
        navigate(`/setting/users`);
      })
      .catch((error: any) => {
        alert("更新に失敗しました。");
        Console_log("post:user", error);
      });
  };

  if (loading) {
    return <Template>loading...</Template>;
  }

  return (
    <Template>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <h1>ユーザ情報編集</h1>
        </Grid>

        <Grid item xs={10}>
          <TextField
            fullWidth
            label="名前"
            value={name}
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            InputProps={{
              readOnly: !flgEdit,
            }}
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            fullWidth
            label="メールアドレス"
            value={email}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              readOnly: !flgEdit,
            }}
          />
        </Grid>

        <Grid item xs={12}></Grid>
        {flgEdit ? (
          <Grid item xs={2}>
            <Button fullWidth variant="contained" onClick={handleUpdate}>
              更新
            </Button>
          </Grid>
        ) : (
          <Grid item xs={10}>
            <Typography variant="body1">
              ログインユーザ以外のユーザ情報は編集できません
            </Typography>
          </Grid>
        )}
      </Grid>
    </Template>
  );
}
