import Template from "./Template";
import { useState, useEffect } from "react";
import { useTheme } from "Theme";
import { useNavigate, Link } from "react-router-dom";
import { Console_log } from "functions/common";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Typography, Button } from "@mui/material";
import PageNation from "components/Pagenation";
import { tUser } from "types/User";
import { getUsers } from "functions/api/mst";

export default function Main() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<tUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const styles = {
    imgList: {
      gridTemplateColumns: "repeat(auto-fit, max(200px))",
      gridTemplateRows: "repeat(auto-fit, max(200px)",
      gap: "16px",
    },
    img: {
      width: "100%",
      height: "100%",
    },
  };

  const TableContainerCustom = styled(TableContainer)(({ theme }) => ({
    "& table": {
      width: "auto",
    },
    "& tr>*": {
      minWidth: "150px",
      maxWidth: "400px",
    },
    "& th.caption": {
      maxWidth: "350px",
    },
    "& td.data": {
      width: "200px",
      "& img": {
        maxWidth: "200px",
        maxHeight: "200px",
      },
    },
  }));

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getUsers({ page: currentPage })
      .then((res: any) => {
        Console_log("get:media", res.data);
        setUsers(res.data.data);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
        setTotalPages(res.data.last_page);
      })
      .catch((error: any) => {
        Console_log("get:media", error);
      });
  }, [currentPage]);

  return (
    <Template>
      <Grid container spacing={2} sx={{ padding: useTheme.spacing(2) }}>
        <Grid item xs={6}>
          <Typography variant="h1">ユーザ一覧</Typography>
        </Grid>
        <Grid item xs={2}>
          <Button component={Link} to={`/setting/users/create`}>
            新規作成
          </Button>
        </Grid>
      </Grid>

      <TableContainerCustom>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell>メールアドレス</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((data: tUser, index: number) => {
              /*
              let title = "";
              if (data.input.title !== undefined) {
                title = data.input.title;
              } else if (data.input.subject !== undefined) {
                title = data.input.subject;
              }
                */

              return (
                <TableRow
                  key={data.id}
                  onClick={() => navigate(`/setting/users/edit/${data.id}`)}
                >
                  <TableCell>{data.name}</TableCell>
                  <TableCell>{data.email}</TableCell>
                  <TableCell>{}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainerCustom>
      <PageNation
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </Template>
  );
}
