import Template from "./Template";
import { useState, useEffect, useContext } from "react";
import { useTheme } from "Theme";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Console_log } from "functions/common";
import * as axiosCustom from "functions/axios";
import { isMediaType } from "const/Media";
import { convertToContentType } from "const/ContentType";
import { tContent, tContentType, tContentTypeDataType } from "types/Content";
import { convertToContent } from "const/Content";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ContentEdit } from "_routes";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import PageNation from "components/Pagenation";
import { getContents } from "functions/api/Content";
import { handleCopyToClipboard } from "functions/common";
import { CommonDataContext } from "Context";

type urlProps = {
  uuidType: string;
};

const TableContainerCustom = styled(TableContainer)(({ theme }) => ({
  "& table": {
    width: "auto",
  },
  "& tr>*": {
    minWidth: "150px",
    maxWidth: "400px",
  },
  "& th.caption": {
    maxWidth: "350px",
  },
  "& td": {
    width: "200px",
    "& img": {
      maxWidth: "200px",
      maxHeight: "200px",
    },
  },
}));

export default function Main() {
  const [type, setType] = useState<tContentType>();
  const [contents, setContents] = useState<tContent[]>([]);
  const { loading, loginUser } = useContext(CommonDataContext);
  const { uuidType } = useParams<urlProps>();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (uuidType === undefined) {
      return;
    }

    getContents(uuidType, {
      page: currentPage,
      order: { field: "created_at", direction: "asc" },
    })
      .then((res: any) => {
        Console_log("get:Contents", res.data);

        const convertedType: tContentType = convertToContentType(
          res.data.content_type
        ); // 適切な型への変換
        setType(convertedType);

        const convertedContents: tContent[] = res.data.listContent.data.map(
          (item: any) => {
            return convertToContent(item);
          }
        );

        setContents(convertedContents);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
        setTotalPages(res.data.last_page);
      })
      .catch((error: any) => {
        Console_log("get:Contents", error);
      });
  }, [uuidType, currentPage]);

  if (type === undefined) {
    return <Template>loading...</Template>;
  } else {
  }

  return (
    <Template>
      <Grid container spacing={2} sx={{ padding: useTheme.spacing(2) }}>
        <Grid item xs={6}>
          <Typography variant="h1">{type["name"]}</Typography>
        </Grid>
        {uuidType !== undefined && loginUser && (
          <>
            <Grid item xs={2}>
              <Button
                onClick={() =>
                  handleCopyToClipboard(
                    `/${loginUser.domain}/content/${uuidType}/list`
                  )
                }
              >
                LIST URL
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() =>
                  handleCopyToClipboard(
                    `/${loginUser.domain}/content/${uuidType}/index`
                  )
                }
              >
                PAGE URL
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={2}>
          <Button component={Link} to={`/content/${uuidType}/create`}>
            新規作成
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
            {type["caption"]}
          </Typography>
        </Grid>
      </Grid>

      <TableContainerCustom>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>caption</TableCell>
              {type["data_type"].map((infoDataType: tContentTypeDataType) => {
                return (
                  <TableCell key={`${infoDataType.uuid}`}>
                    {infoDataType["label"]}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {contents.map((content: tContent, index: number) => (
              <TableRow
                key={content.uuid}
                onClick={() =>
                  navigate(`/content/${uuidType}/edit/${content.uuid}`)
                }
              >
                <TableCell>{content["caption"] || ""}</TableCell>
                {type["data_type"].map((infoDataType: tContentTypeDataType) => {
                  const contentItems = content.items[infoDataType["label"]];
                  return (
                    <TableCell>
                      {isMediaType(contentItems) ? (
                        <img
                          src={contentItems["url"]}
                          alt={contentItems["caption"]}
                        />
                      ) : (
                        <p>{`${content["items"][infoDataType["label"]]}`}</p>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerCustom>
      <PageNation
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </Template>
  );
}
