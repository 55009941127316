import MainFrame, { SubMenuTemplate } from "templates/MainFrame";
import * as routes from "_routes";

export default function Template({ children }: { children: React.ReactNode }) {
  const defMenu = {
    path: routes.routeLogout.path,
    label: routes.routeLogout.label,
  };

  const subMenu = [defMenu, routes.routeDomainInfo, routes.routeUsers];

  return (
    <MainFrame subMenu={<SubMenuTemplate subMenu={subMenu} />}>
      {children}
    </MainFrame>
  );
}
