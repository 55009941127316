import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface ProtectedRouteProps {
  isAuthenticated: boolean;
  children: ReactNode;
}

export const ProtectedRoute = ({
  isAuthenticated,
  children,
}: ProtectedRouteProps) => {
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/Login" state={{ from: location }} replace />;
  }
  return <>{children}</>;
};
