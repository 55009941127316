import { create } from "functions/axios";
import { tDomain } from "types/Domain";

export function getLoginUser() {
  const ins = create();
  return ins.get(`/login-user`, {  });
}
// ユーザ
export function getUsers(params: any = {}) {
  const ins = create();
  return ins.get(`/mst/user/index`, { params: params });
}
export function getUserList(params: any = {}) {
  const ins = create();
  return ins.get(`/mst/user/list`, { params: params });
}
export function getUser(id: string) {
  const ins = create();
  return ins.get(`/mst/user/${id}`, {});
}
export function storeUser(params: any) {
  const ins = create();
  return ins.post(`/mst/user/store`, params);
}
export function updateUser(id: string, params: any) {
  const ins = create();
  return ins.post(`/mst/user/update/${id}`, params);
}
export function forgetPassword(email: string) {
  const ins = create();
  return ins.post(`/forget-password`, {email: email});
}
export function resetPassword(params: any) {
  const ins = create();
  return ins.post(`/reset-password`, params);
}

// 組織
export function getOrganize(domain: string) {
  const ins = create();
  return ins.get(`/mst/domain/${domain}`);
}
export function updateOrganize(params: tDomain) {
  const ins = create();
  return ins.post(`/mst/domain/update/${params.domain}`, params);
}
