import React, { createContext, useContext, useState, ReactNode } from "react";

// 型定義
interface AuthContextType {
  isAuthenticated: boolean;
  login: (data: any) => void;
  logout: () => void;
}

// Contextの作成
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Providerの型定義
interface AuthProviderProps {
  children: ReactNode;
}

// AuthProviderコンポーネント
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!localStorage.getItem("token")
  );

  const login = (data: any) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("datetime", new Date().toISOString());
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem("token"); // トークンを削除
    localStorage.removeItem("datetime");
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// カスタムフック
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
