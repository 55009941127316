import Template from "./Template";
import { useState, useEffect, useContext } from "react";
import { Console_log } from "functions/common";
import { useParams, useNavigate } from "react-router-dom";
import { Button, TextField, Grid } from "@mui/material";
import { storeUser } from "functions/api/mst";
import { CommonDataContext } from "Context";

export default function Create() {
  const [caption, setCaption] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { loading, loginUser } = useContext(CommonDataContext);
  const navigate = useNavigate();

  //登録ボタンを押下したときの処理
  const handleStore = () => {
    if (loginUser === null) return;

    storeUser({
      domain: loginUser.domain,
      name: name,
      email: email,
    })
      .then((res: any) => {
        //setWeekData(res.data);
        alert(res.data.message + "\n初期パスワード：" + res.data.password);
        navigate(`/setting/users`);
      })
      .catch((error: any) => {
        alert("登録に失敗しました。");
        Console_log("post:user", error);
      });
  };

  if (loading) {
    return <Template>loading...</Template>;
  }

  return (
    <Template>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <h1>新規ユーザ作成</h1>
        </Grid>

        <Grid item xs={10}>
          <TextField
            fullWidth
            label="名前"
            value={name}
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            fullWidth
            label="メールアドレス"
            value={email}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}></Grid>
        <Grid item xs={2}>
          <Button fullWidth variant="contained" onClick={handleStore}>
            登録
          </Button>
        </Grid>
      </Grid>
    </Template>
  );
}
