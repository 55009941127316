import react, { useState, useEffect } from "react";
import { Console_log } from "functions/common";
import MainFrame, { SubMenu02Template } from "templates/MainFrame";
import * as routes from "_routes";
import * as axiosCustom from "functions/axios";
import { getDomains } from "functions/api/WDC";

export default function Template({ children }: { children: React.ReactNode }) {
  const [subMenu, setSubMenu] = useState<{
    [key: string]: { path: string; label: string }[];
  }>({});

  useEffect(() => {
    getDomains()
      .then((res) => {
        Console_log("get:domains", res.data);

        const contentTypeList: {
          [key: string]: { path: string; label: string }[];
        } = {};
        res.data.forEach(function (domains: any) {
          // code
          contentTypeList[domains.name] = [
            {
              path: `/wdc/${domains.id}`,
              label: "TOP",
            },
            {
              path: `/wdc/${domains.id}/pages`,
              label: "pages",
            },
            /*
            {
              path: `/wdc/${domains.id}/user`,
              label: "users",
            },
            */
          ];
        });
        console.log(contentTypeList);
        // コンテンツタイプリストを展開してセットする
        //const mergedSubMenu = contentTypeList.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        setSubMenu(contentTypeList);
      })
      .catch((error: any) => {
        Console_log("get:domains", error);
      });
  }, []);

  return (
    <MainFrame subMenu={<SubMenu02Template subMenus={subMenu} />}>
      {children}
    </MainFrame>
  );
}
