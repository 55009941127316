import { useEffect, useState } from "react";
import * as axios from "functions/axios";
import { Console_log } from "functions/common";
import { useAuth } from "AuthContext"; // AuthContext をインポート
import { useNavigate } from "react-router-dom";

import Link from "@mui/material/Link";
import { linkName } from "_routes";
import LoginFrame from "templates/LoginFrame";

export default function Expenses() {
  const [useMessage, setMessage] = useState("ログアウト中");

  const navigate = useNavigate();
  const { logout } = useAuth(); // useAuth で logout を取得

  // 初回レンダリング後と、useした値の更新後に自動で実行
  useEffect(() => {
    let mount = true; //マウントされているか
    console.log("logout useEffect");
    console.log(localStorage.getItem("token"));

    if (mount) {
      axios.logout(function (res: any) {
        Console_log("callback", res);
        if (res.status === 200) {
          logout(); // トークンを削除し、認証状態を更新
          setMessage("ログアウトに成功しました。");
          navigate("/login", { replace: true });
        } else {
          setMessage("ログアウトに失敗しました。");
        }
      });
    }

    return () => {
      mount = false;
    }; //クリーンアップでマウントフラグをfalseに
  }, []);

  return (
    <LoginFrame style={{ padding: "1rem 0" }}>
      <h2>{useMessage}</h2>
      <Link href={linkName["login"]["path"]}>login</Link>
    </LoginFrame>
  );
}
