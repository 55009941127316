import { useEffect, useState } from "react";
import * as axios from "functions/axios";
import Template from "pages/wdc/Template";
import { getDomains } from "functions/api/WDC";
import { tWebDomain } from "types/WebDomain";
import { Link, useParams, useNavigate } from "react-router-dom";

export default function Mypage() {
  const navigate = useNavigate();
  getDomains()
    .then((res) => {
      const domains: tWebDomain[] = res.data;

      navigate(`/wdc/${domains[0].id}`);
    })
    .catch((error: any) => {});

  return (
    <Template>
      <h2>MyPage</h2>
    </Template>
  );
}
