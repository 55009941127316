// index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AppRoutes } from "_routes";
import { AuthProvider, useAuth } from "AuthContext";
import { CommonDataProvider } from "Context";
// index.cssを読み込む
import "./index.css";

const rootDom = document.getElementById("root");

// App コンポーネントで useAuth を使うので AuthProvider でラップ
const App: React.FC = () => {
  const { isAuthenticated } = useAuth(); // useAuth を呼び出して認証状態を取得
  const router = createBrowserRouter(AppRoutes(isAuthenticated)); // 認証状態に基づいてルーティング

  return <RouterProvider router={router} />;
};

if (rootDom) {
  const root = ReactDOM.createRoot(rootDom);
  root.render(
    <AuthProvider>
      <CommonDataProvider>
        <App /> {/* AuthProvider の内側で App をレンダリング */}
      </CommonDataProvider>
    </AuthProvider>
  );
}
