import { create } from "functions/axios";

/**
 * 対象ドメイン一覧の取得
 * @returns
 */
export function getDomains() {
  const ins = create();
  return ins.get(`/web-domain/index`, {});
}
/**
 * 対象ドメインの取得
 * @returns
 */
export function getDomain(id: string) {
  const ins = create();
  return ins.get(`/web-domain/${id}`, {});
}

const initURL = "wdc/event-data";

/**
 * セッション別ユーザ数を取得
 * @returns
 */
export function getUserCount(id: string) {
  const ins = create();
  return ins.get(`${initURL}/user-count`, { params: { domain_id: id } });
}

/**
 * 日別のユーザ数、ビュー数、クリック数を取得
 * @returns
 */
export function getWeekSummary(id: string) {
  const ins = create();
  return ins.get(`${initURL}/week-summary`, { params: { domain_id: id } });
}

/**
 * ドメイン別イベント数を取得
 * @returns
 */
export function getEventCountByDomain(id: string) {
  const ins = create();
  return ins.get(`${initURL}/event-count/domain`, {
    params: { domain_id: id },
  });
}

/**
 * ページ別イベント数を取得
 * @returns
 */
export function getEventCountByPage(id: string) {
  const ins = create();
  return ins.get(`${initURL}/event-count/page`, { params: { domain_id: id } });
}
