import Template from "./Template";
import { useState, useEffect } from "react";
import { Console_log } from "functions/common";
import { useParams, useNavigate } from "react-router-dom";
import { tContactItem, tEndInput } from "types/EndInput";
import { Button, TextField, Grid, useTheme, Typography } from "@mui/material";
import {
  destroyEndInput,
  getEndInput,
  importEmail,
} from "functions/api/EndInput";
import { isEndInput } from "const/EndInput";

type urlProps = {
  uuidType: string;
  uuid: string;
};
export default function Create() {
  const theme = useTheme();
  const [input, setInput] = useState<tEndInput>(isEndInput);
  const { uuid } = useParams<urlProps>();
  const navigate = useNavigate();

  // コンテンツ情報の取得
  useEffect(() => {
    console.log("uuid", uuid);
    if (uuid !== undefined) {
      // ContentTypeの取得
      // Contentの取得
      getEndInput(uuid)
        .then((res: any) => {
          Console_log("get:Content.edit", res.data);

          setInput(res.data);
        })
        .catch((error: any) => {
          Console_log("error get:endiput.show", error);
          setInput(isEndInput);
        });
    } else {
      setInput(isEndInput);
    }
  }, [uuid]);

  const handleImportEmail = () => {
    if (uuid === undefined) {
      return;
    }
    Console_log("get:EndInput", input);

    // 確認用メッセージを表示
    const confirmMessage = "取り込みしますか？";
    if (!window.confirm(confirmMessage)) {
      return;
    }
    importEmail(uuid)
      .then((res: any) => {
        alert("メールをお送りいたしました。ご確認下さい");
      })
      .catch((error: any) => {
        alert("メール送信に失敗しました。");
      });
  };

  // 削除ボタン
  const handleDelete = () => {
    if (uuid === undefined) {
      return;
    }
    Console_log("delete:EndInput", input);

    // 確認用メッセージを表示
    const confirmMessage = "削除しますか？";
    if (!window.confirm(confirmMessage)) {
      return;
    }
    destroyEndInput(uuid);
    navigate("/end-input");
  };

  if (!input) {
    return <Template>loading...</Template>;
  }

  const items: tContactItem[] = input.input;

  return (
    <Template>
      <Grid container spacing={2} alignItems={"stretch"}>
        <Grid item xs={8}>
          <h1>{input.created_at}</h1>
        </Grid>
        <Grid item xs={2}>
          {uuid !== undefined && (
            <Button
              onClick={() => {
                handleImportEmail();
              }}
            >
              取り込み
            </Button>
          )}
        </Grid>
        <Grid item xs={2}>
          {uuid !== undefined && (
            <Button
              onClick={() => {
                handleDelete();
              }}
            >
              削除
            </Button>
          )}
        </Grid>
        {items.map((item, index: number) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={4}
                key={`endInput-${input.uuid}-input-${index}-label`}
                sx={{
                  [theme.breakpoints.up("md")]: {
                    borderBottom: "1px solid white",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 700, p: theme.spacing(2) }}
                >
                  {item.label}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                key={`endInput-${input.uuid}-input-${index}-value`}
                sx={{ borderBottom: "1px solid white" }}
              >
                <Typography variant="body2" sx={{ p: theme.spacing(2) }}>
                  {item.value}
                </Typography>
              </Grid>
            </>
          );
        })}
      </Grid>
    </Template>
  );
}
