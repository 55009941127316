import { useState } from "react";
import * as axios from "functions/axios";
import * as env from "functions/env";
import { useParams, useNavigate } from "react-router-dom";

//import Grid from '@mui/material/Grid';
import Grid from "@mui/material/Grid"; // Grid version 2
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { resetPassword } from "functions/api/mst";
//import LoadingButton from '@mui/lab/LoadingButton';
import Link from "@mui/material/Link";

type urlProps = {
  token: string;
  email: string;
};
export default function Main() {
  let axiosIns: any;
  axiosIns = axios.create();
  const navigate = useNavigate();

  const { token, email } = useParams<urlProps>();
  const [usePass, setPass] = useState("");
  const [useConfirmPass, setConfirmPass] = useState("");
  const confirmChange = (e: any) => setConfirmPass(e.target.value);
  const passChange = (e: any) => setPass(e.target.value);

  const postPasswordReset = () => {
    if (usePass !== useConfirmPass) {
      console.log("パスワードが一致しません。");
      return;
    }

    const data = {
      token: token,
      email: email,
      password: usePass,
      password_confirmation: useConfirmPass,
    };

    resetPassword(data)
      .then((res: any) => {
        console.log(res);
        alert(res.data.message);
        navigate("/login");
      })
      .catch((err: any) => {
        console.log("err:", err);
        alert("エラーが発生しました。");
      });
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4">
          Password Reset
        </Typography>

        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
            value={usePass}
            onChange={passChange}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="confirm"
            label="確認"
            type="password"
            id="confirm-password"
            autoComplete="current-password"
            value={useConfirmPass}
            onChange={confirmChange}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={postPasswordReset}
          >
            Register
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
