export const getNowOrigin = strDateTimeOrigin(dateTime(0, 0, 0, 0, 0, 0));

export function getNow() {
  let obj = dateTime(0, 0, 0, 0, 0, 0);
  return strDateTime(obj);
}
export function getAddOneDay() {
  let obj = dateTime(0, 0, 1, 0, 0, 0);
  return strDateTime(obj);
}
export function getAddOneMin() {
  let obj = dateTime(0, 0, 0, 0, 1, 0);
  return strDateTime(obj);
}
/**
 *
 * @param objData
 * @returns string  "yyyy-MM-dd hh:mm:ss"
 */
export function strDateTimeOrigin(objData: Date) {
  let strYear = String(objData.getFullYear());
  let strMonth = String(objData.getMonth() + 1);
  let strDate = String(objData.getDate());
  let strHour = String(objData.getHours());
  let strMin = String(objData.getMinutes());
  let strSec = String(objData.getSeconds());

  let result = "";
  result = result.concat(strYear + "/");
  result = result.concat(strMonth + "/");
  result = result.concat(strDate + " ");
  result = result.concat(strHour + ":");
  result = result.concat(strMin + ":");
  result = result.concat(strSec);

  return result;
}

/**
 *
 * @param objData
 * @param separate
 * @returns string
 */
function strDateTime(objData: Date, separate: string = "") {
  let strYear = String(objData.getFullYear());
  let strMonth = String(objData.getMonth());
  let strDate = String(objData.getDate());
  let strHour = String(objData.getHours());
  let strMin = String(objData.getMinutes());
  let strSec = String(objData.getSeconds());

  let result = "";
  result = result.concat(strYear + separate);
  result = result.concat(strMonth + separate);
  result = result.concat(strDate + separate);
  result = result.concat(strHour + separate);
  result = result.concat(strMin + separate);
  result = result.concat(strSec + separate);

  return result;
}

function dateTime(
  addYear: number,
  addMonth: number,
  addDay: number,
  addHour: number,
  addMin: number,
  addSec: number
) {
  let date = new Date();

  date.setFullYear(date.getFullYear() + addYear);
  date.setMonth(date.getMonth() + addMonth);
  date.setDate(date.getDate() + addDay);
  date.setHours(date.getHours() + addHour);
  date.setMinutes(date.getMinutes() + addMin);
  date.setSeconds(date.getSeconds() + addSec);

  return date;
}

/**
 * Dateオブジェクトを'YYYY-MM-DDTHH:mm'形式の文字列に変換
 * @param date
 * @returns
 */
export const convertInputDate = (date: Date): string => {
  const padZero = (num: number) => (num < 10 ? `0${num}` : num);
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
