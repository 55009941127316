import React from "react";
import { createBrowserRouter, RouteObject, Navigate } from "react-router-dom";
import { ProtectedRoute } from "components/ProtectedRoute";

import Http404 from "Http404";
import NotFound from "Notfound";

import Setting from "pages/setting/Index";
import Dashboard from "pages/Index";
import Login from "pages/Login";
import Logout from "pages/Logout";
import OverLimit from "pages/OverLimit";
import Register from "pages/Register";
import ForgetPassword from "pages/ForgetPassword";
import PageResetPassword from "pages/ResetPassword";

import PageWDC from "pages/wdc/Index";
import PageDomainDetail from "pages/wdc/[domain_id]/Index";
import PageDomainPages from "pages/wdc/[domain_id]/pages";

import PageContentType from "pages/content-type/Index";
import PageContentTypeCreate from "pages/content-type/Create";
import PageContentTypeEdit from "pages/content-type/Edit";

// コンテンツ
import PageContent from "pages/content/Index";
import PageContentList from "pages/content/List";
import PageContentEdit from "pages/content/Edit";
import PageMedia from "pages/media/Index";
import PageMediaEdit from "pages/media/Edit";
import PageInput from "pages/endInput/Index";
import PageInputShow from "pages/endInput/Show";

// 設定
import PageDomainInfo from "pages/setting/Domain";
import PageUsers from "pages/setting/Users";
import PageCreateUser from "pages/setting/CreateUser";
import PageEditUser from "pages/setting/EditUser";

// ルート情報の型
export type RouteType = {
  path: string;
  label: string;
  element: React.ReactNode;
};

export const routeTop: RouteType = {
  path: "/",
  label: "top",
  element: <Dashboard />,
};

// ContentType関係
export const ContentTypeTop: RouteType = {
  path: "/content-type",
  label: "content type",
  element: <PageContentType />,
};
export const ContentTypeCreate: RouteType = {
  path: "/content-type/create",
  label: "Create",
  element: <PageContentTypeCreate />,
};
export const ContentTypeEdit: RouteType = {
  path: "/content-type/:uuid",
  label: "content-type edit",
  element: <PageContentTypeEdit />,
};

// Content関係
export const ContentTop: RouteType = {
  path: "/content",
  label: "content top",
  element: <PageContent />,
};
export const ContentList: RouteType = {
  path: "/content/:uuidType",
  label: "content list",
  element: <PageContentList />,
};
export const ContentCreate: RouteType = {
  path: "/content/:uuidType/create",
  label: "content create",
  element: <PageContentEdit />,
};
export const ContentEdit: RouteType = {
  path: "/content/:uuidType/edit/:uuid",
  label: "content edit",
  element: <PageContentEdit />,
};

// Media関係
export const MediaTop: RouteType = {
  path: "/media",
  label: "List",
  element: <PageMedia />,
};
export const MediaCreate: RouteType = {
  path: "/media/create",
  label: "Create",
  element: <PageMediaEdit />,
};
export const MediaEdit: RouteType = {
  path: "/media/:uuid",
  label: "Edit",
  element: <PageMediaEdit />,
};

// Input関係
export const InputTop: RouteType = {
  path: "/end-input",
  label: "Input",
  element: <PageInput />,
};
export const InputShow: RouteType = {
  path: "/end-input/:uuid",
  label: "Show",
  element: <PageInputShow />,
};

// WDC関係
export const wdcTop: RouteType = {
  path: "/wdc",
  label: "WDC top",
  element: <PageWDC />,
};
export const wdcDetail: RouteType = {
  path: "/wdc/:domain_id/",
  label: "WDC Detail",
  element: <PageDomainDetail />,
};
export const wdcPages: RouteType = {
  path: "/wdc/:domain_id/pages",
  label: "WDC Pages",
  element: <PageDomainPages />,
};

// その他システム機能
export const routeSetting: RouteType = {
  path: "/setting",
  label: "",
  element: <Setting />,
};
export const routeDomainInfo: RouteType = {
  path: "/setting/domain",
  label: "組織情報",
  element: <PageDomainInfo />,
};
export const routeUsers: RouteType = {
  path: "/setting/users",
  label: "ユーザ一覧",
  element: <PageUsers />,
};
export const routeCreateUser: RouteType = {
  path: "/setting/users/create",
  label: "新規ユーザ作成",
  element: <PageCreateUser />,
};
export const routeEditUser: RouteType = {
  path: "/setting/users/edit/:id",
  label: "ユーザ編集",
  element: <PageEditUser />,
};

export const routeLogin: RouteType = {
  path: "/Login",
  label: "top",
  element: <Login />,
};
export const routeLogout: RouteType = {
  path: "/Logout",
  label: "LOGOUT",
  element: <Logout />,
};
export const routeRegister: RouteType = {
  path: "/Register",
  label: "top",
  element: <Register />,
};
export const routeForgetPassword: RouteType = {
  path: "/forget-password",
  label: "Forget Password",
  element: <ForgetPassword />,
};
export const routePasswordReset: RouteType = {
  path: "/reset-password/:token/:email",
  label: "Password Reset",
  element: <PageResetPassword />,
};
export const http404: RouteType = {
  path: "/http404",
  label: "top",
  element: <Http404 />,
};
export const overLimit: RouteType = {
  path: "/overlimit",
  label: "top",
  element: <OverLimit />,
};
export const notRoute: RouteType = {
  path: "*",
  label: "top",
  element: <NotFound />,
};

/*
export function LoggedIn() {
  return createBrowserRouter([
    routeTop,
    routeLogin,
    routeLogout,
    routeSetting,
    routeDomainInfo,
    routeUsers,
    routeCreateUser,
    routeEditUser,

    ContentTypeTop,
    ContentTypeCreate,
    ContentTypeEdit,

    ContentTop,
    ContentList,
    ContentCreate,
    ContentEdit,

    MediaTop,
    MediaCreate,
    MediaEdit,

    InputTop,
    InputShow,

    wdcTop,
    wdcDetail,
    wdcPages,

    overLimit,
    http404,
    routeRegister,
    routeForgetPassword,
    routePasswordReset,
    notRoute,
  ]);
}

export function NotLoggedIn() {
  return createBrowserRouter([
    routeLogin,
    routeRegister,
    routeForgetPassword,
    routePasswordReset,
    http404,
    notRoute,
  ]);
}
  */
export function AppRoutes(isAuthenticated: boolean) {
  const routes = [
    {
      path: "/",
      element: isAuthenticated ? (
        <ProtectedRoute isAuthenticated={isAuthenticated}>
          <Dashboard />
        </ProtectedRoute>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: routeLogin.path,
      element: isAuthenticated ? <Navigate to="/" /> : routeLogin.element,
    },
    routeRegister,
    routeForgetPassword,
    routePasswordReset,
  ];

  const isAuthPages = [
    routeLogout,
    routeSetting,
    routeDomainInfo,
    routeUsers,
    routeCreateUser,
    routeEditUser,
    ContentTypeTop,
    ContentTypeCreate,
    ContentTypeEdit,
    ContentTop,
    ContentList,
    ContentCreate,
    ContentEdit,
    MediaTop,
    MediaCreate,
    MediaEdit,
    InputTop,
    InputShow,
    wdcTop,
    wdcDetail,
    wdcPages,
    overLimit,
    http404,
    notRoute,
  ];

  // 認証が必要なルートを追加
  isAuthPages.forEach((route) => {
    routes.push({
      path: route.path,
      element: (
        <ProtectedRoute isAuthenticated={isAuthenticated}>
          {route.element}
        </ProtectedRoute>
      ),
    });
  });

  return routes;
}

export const linkName = {
  top: routeTop,
  login: routeLogin,
  logout: routeLogout,
  register: routeRegister,
  wdcTop: wdcTop,

  wdcDetail: wdcDetail,

  overLimit: overLimit,

  http404: http404,
  notRoute: notRoute,
};

export const linkNameWdc = {
  top: wdcTop,
  wdcDetail: wdcDetail,
};
